import * as React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const GeneralConditionsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { slug: { eq: "cgv" } }) {
        html
      }
    }
  `)
  const {
    markdownRemark: { html },
  } = data
  return (
    <Layout>
      <SEO title="Conditions Générales de Vente (CGV)" />
      <div style={{ background: "#000000a3", padding: "15px" }}>
        <h1>Conditions Générales de Vente (CGV)</h1>
        <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
        <Link to="/">Retour à l'accueil</Link>
      </div>
    </Layout>
  )
}
export default GeneralConditionsPage
